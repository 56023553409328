import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import { Box, Grid, SliderIndicator } from '@core';

const ANIMATION_DURATION = 300;
const MIN_SWIPE_DISTANCE = 50;

const slideIn = (index) => keyframes`
  from {
    transform: translateX(${(index - 1) * -100}%);
  }

  to {
    transform: translateX(${index * -100}%);
  }
`;

const slideOut = (index) => keyframes`
  from {
    transform: translateX(${(index + 1) * -100}%);
  }
  
  to {
    transform: translateX(${index * -100}%);
  }
`;

const AnimationWrapper = styled.div`
  width: 100%;
  display: flex;
  transform: ${({ initialTranslateAmount }) => `translateX(${initialTranslateAmount})`};

  animation-name: ${({ animationName }) => animationName};
  animation-duration: ${({ animationDuration }) => `${animationDuration}ms`};
  animation-fill-mode: forwards;
`;

const WrapperWithClickHandler = styled.button`
  /* reset properties */
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 0;
  margin: 0;
  display: flex;
`;

const TabsSliderContainer = ({
  tabsData,
  openItem,
  changeTabHandler,
  headerWrapper: HeaderWrapper,
  labelComponent: LabelComponent,
  contentComponent: ContentComponent,
  animationName,
  animationDuration,
  handleTouchStart,
  handleTouchEnd,
  hideSliderIndicator,
}) => (
  <Grid.Container onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
    {LabelComponent && (
      <Grid.Row>
        <HeaderWrapper>
          {tabsData.map(({ labelProps }, index) => (
            <WrapperWithClickHandler key={index} onClick={() => changeTabHandler(index)}>
              <LabelComponent isOpen={openItem === index} {...labelProps} />
            </WrapperWithClickHandler>
          ))}
        </HeaderWrapper>
      </Grid.Row>
    )}
    <Grid.Row overflowX="hidden">
      <AnimationWrapper
        animationName={animationName}
        animationDuration={animationDuration}
        initialTranslateAmount={`${openItem * -100}%`}
      >
        {tabsData.map(({ contentProps }, index) => (
          <ContentComponent key={index} isOpen={openItem === index} {...contentProps} />
        ))}
      </AnimationWrapper>
    </Grid.Row>
    {!hideSliderIndicator && (
      <SliderIndicator
        items={tabsData.length}
        activeItem={openItem}
        handleClick={(index) => changeTabHandler(index)}
      />
    )}
  </Grid.Container>
);

const TabsSliderWithInnerControl = ({
  headerWrapper,
  tabsData,
  labelComponent,
  contentComponent,
  onOpen,
  animationDuration,
  hideSliderIndicator,
}) => {
  const touchStartX = useRef(0);
  const [openItem, setOpenItem] = useState(0);
  const [animationName, setAnimationName] = useState();

  const handleTabChange = (index) => {
    if (index !== openItem) {
      if (index > openItem) {
        setOpenItem(index);
        setAnimationName(slideIn(index));
      } else {
        setOpenItem(index);
        setAnimationName(slideOut(index));
      }

      if (onOpen) {
        onOpen(index);
      }
    }
  };

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    touchStartX.current = touch.clientX;
  };

  const handleTouchEnd = (e) => {
    const touch = e.changedTouches[0];
    const diffX = touch.clientX - touchStartX.current;
    const absX = Math.abs(diffX);

    if (absX >= MIN_SWIPE_DISTANCE) {
      if (diffX < 0) {
        // swiped left
        const nextPage = openItem < tabsData.length - 1 ? openItem + 1 : openItem;
        handleTabChange(nextPage);
      } else {
        // swiped right
        const nextPage = openItem > 0 ? openItem - 1 : openItem;
        handleTabChange(nextPage);
      }
    }
  };

  return (
    <TabsSliderContainer
      tabsData={tabsData}
      openItem={openItem}
      changeTabHandler={handleTabChange}
      headerWrapper={headerWrapper}
      labelComponent={labelComponent}
      contentComponent={contentComponent}
      animationName={animationName}
      animationDuration={animationDuration}
      handleTouchStart={handleTouchStart}
      handleTouchEnd={handleTouchEnd}
      hideSliderIndicator={hideSliderIndicator}
    />
  );
};

const TabsSliderWithExternalControl = ({
  headerWrapper,
  tabsData,
  labelComponent,
  contentComponent,
  onOpen,
  animationDuration,
  externalOpenedTab,
  openExternal,
  hideSliderIndicator,
}) => {
  const [openItem, setOpenItem] = useState(externalOpenedTab);
  const [animationName, setAnimationName] = useState();

  useEffect(() => {
    const nextItem =
      externalOpenedTab > tabsData.length - 1
        ? tabsData.length - 1
        : externalOpenedTab < 0
        ? 0
        : externalOpenedTab;

    const handleTabChange = (index) => {
      if (index !== openItem) {
        if (index > openItem) {
          setOpenItem(index);
          setAnimationName(slideIn(index));
        } else {
          setOpenItem(index);
          setAnimationName(slideOut(index));
        }

        if (onOpen) {
          onOpen(index);
        }
      }
    };

    handleTabChange(nextItem);
  }, [externalOpenedTab, onOpen, openItem, tabsData.length]);

  return (
    <TabsSliderContainer
      tabsData={tabsData}
      openItem={openItem}
      changeTabHandler={openExternal}
      headerWrapper={headerWrapper}
      labelComponent={labelComponent}
      contentComponent={contentComponent}
      animationName={animationName}
      animationDuration={animationDuration}
      hideSliderIndicator={hideSliderIndicator}
    />
  );
};

const TabsSlider = ({
  headerWrapper = ({ children }) => (
    <Box width="100%" display="flex">
      {children}
    </Box>
  ),
  tabsData = [],
  labelComponent,
  contentComponent,
  onOpen,
  externalOpenedTab,
  openExternal,
  animationDuration = ANIMATION_DURATION,
  hideSliderIndicator = false,
}) =>
  typeof externalOpenedTab === 'number' && openExternal ? (
    <TabsSliderWithExternalControl
      headerWrapper={headerWrapper}
      tabsData={tabsData}
      labelComponent={labelComponent}
      contentComponent={contentComponent}
      onOpen={onOpen}
      animationDuration={animationDuration}
      externalOpenedTab={externalOpenedTab}
      openExternal={openExternal}
      hideSliderIndicator={hideSliderIndicator}
    />
  ) : (
    <TabsSliderWithInnerControl
      headerWrapper={headerWrapper}
      tabsData={tabsData}
      labelComponent={labelComponent}
      contentComponent={contentComponent}
      onOpen={onOpen}
      animationDuration={animationDuration}
      hideSliderIndicator={hideSliderIndicator}
    />
  );

export default TabsSlider;
